import '../scss/app.scss';

// Your JS Code goes here
// import Hero from '../components/hero/hero.js'
import Header from '../components/header/header.js'
import Video from '../components/video/video.js'
import WorkFilter from '../components/work-filter/work-filter.js'
import Clock from '../components/clock/clock.js'
import Accordion from '../components/accordion/accordion.js';
import Footer from '../components/footer/footer.js';
import BackButton from '../components/button/backbutton.js';
import ContactForm from '../components/contactform/contactform.js';
// import FounderVideo from '../components/foundervideo/foundervideo.js'
// import Features from '../components/features/features.js'
// import Mobilemenu from '../components/mobilemenu/mobilemenu.js'
// import Storytelling from '../components/storytelling/storytelling.js'
// import ScrollFadeIn from '../components/scrollfadein/scrollfadein.js'
// import WebGL from '../components/webgl/webgl.js'
// import barba from '@barba/core';
// import { gsap } from "gsap";

let footerEl = document.querySelector(".footer");
let footer = new Footer();
// console.log(footer)
footer.init(footerEl)

let accordions = document.querySelectorAll(".accordion");
accordions.forEach(accordionEl => {
  let accordion = new Accordion();
  accordion.init(accordionEl)
})

let headerEl = document.querySelector(".header");
let header = new Header();
header.init(headerEl)

let clocks = document.querySelectorAll(".clock");
clocks.forEach(clockEl => {
  let clock = new Clock();
  clock.init(clockEl)
})

let videos = document.querySelectorAll(".simple-video");
videos.forEach(videoEl => {
  let video = new Video();
  video.init(videoEl)
})

let backbuttons = document.querySelectorAll(".backbutton");
backbuttons.forEach(backbuttonEl => {
  let backbutton = new BackButton();
  backbutton.init(backbuttonEl)
})

window.setTimeout(() => {
	document.querySelectorAll('.video-autoplay').forEach(video => {
		// console.log(video)
		video.muted = true;
		video.play().catch(error => {
			console.error('Autoplay was prevented:', error);
		});
	});
}, 4000);

if(document.querySelector(".work-has-filter")) {
	let workFilter = new WorkFilter();
	let workFilterEl = document.querySelector(".work-has-filter");
	workFilter.init(workFilterEl)
}

const contactFormEl = document.querySelector('.contactform');
if(contactFormEl) {
	const contactForm = new ContactForm();
	contactForm.init(contactFormEl);
}