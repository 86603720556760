export default class Accordion {
    init(root) {
      this.root = root; // .accordion element
      this.render();
    }
  
    render() {
      const items = this.root.querySelectorAll('.accordion-item');
      items.forEach((item) => {
        const header = item.querySelector('.accordion-header');
        const icon = item.querySelector('.accordion-icon');
        const content = item.querySelector('.accordion-content');
  
        const toggleAccordion = () => {
          if (item.classList.contains('open')) {
            item.classList.remove('open');
            item.classList.add('closed');
          } else {
            item.classList.remove('closed');
            item.classList.add('open');
          }
        };
  
        header.addEventListener('click', toggleAccordion);
        icon.addEventListener('click', toggleAccordion);
  
        // item.classList.add('closed');
      });
    }
  }
  