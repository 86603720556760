export default class BackButton {
    init(root) {
        this.root = root;
        this.addHistoryLink()
    }

    addHistoryLink() {
        // console.log('init BackButton')
        const referrer = document.referrer;
        if (referrer && new URL(referrer).origin === window.location.origin) { // Check if there is a referrer and if it's from the same domain
            // console.log('overwrite to history back')
            this.root.addEventListener('click', (e) => {
                e.preventDefault();
                window.history.back();
            });
        } else {
            // console.log('no history; keep link')
            //using fallback from CMS instead
            // window.location.href = '/'; // fallback to the homepage or another default URL
        }
    }
}
