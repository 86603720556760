export default class Footer {
    init(root) {
        this.root = root;
        this.footerSpacer = document.querySelector('.footer-spacer');
        this.footer = document.querySelector('.footer');
        this.header = document.querySelector('.header');
        this.updateMargin();
        window.addEventListener('resize', () => this.updateMargin());
    }

    updateMargin() {
        const footerHeight = this.footer.offsetHeight;
        const headerBottom = this.header.getBoundingClientRect().bottom + 32;
        const extraSpace = 32;
        const requiredSpace = window.innerHeight - headerBottom;

        if(footerHeight < requiredSpace) {
            this.footer.style.position = "fixed";
            this.footerSpacer.style.height = `${requiredSpace}px`;
        } else {
            this.footer.style.position = "relative";
            this.footerSpacer.style.height = `${0}px`;
        }
    }
}
