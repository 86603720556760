export default class Header {
    init(root) {
        this.root = root
        this.render()
    }

    render() {
        let icon = document.querySelector('.burger-icon');
        icon.addEventListener('click', e => {
            icon.classList.toggle('is-active');
            this.root.classList.toggle('is-open');
        })
    }
}
