// Clock.js
export default class Clock {
    init(root) {
        this.root = root; // contains element clock
        this.render();
        this.startClock();
    }

    render() {
        this.clockElement = document.createElement('div');
        this.clockElement.id = 'clock';
        this.clockElement.style.width = '100%';
        this.clockElement.style.textAlign = 'right';
        this.root.style.width = '100%';
        this.root.appendChild(this.clockElement);
    }

    updateClock() {
        const now = new Date();
        
        // Get the current time in Hamburg
        const hamburgTime = new Date(now.toLocaleString("en-US", {timeZone: "Europe/Berlin"}));
        
        const hours = String(hamburgTime.getHours()).padStart(2, '0');
        const minutes = String(hamburgTime.getMinutes()).padStart(2, '0');
        const seconds = hamburgTime.getSeconds();
        const colon = seconds % 2 === 0 ? ':' : ' ';
        
        // Determine if it is CET or CEST
        const jan = new Date(hamburgTime.getFullYear(), 0, 1);
        const jul = new Date(hamburgTime.getFullYear(), 6, 1);
        const isDaylightSaving = Math.min(jan.getTimezoneOffset(), jul.getTimezoneOffset()) === hamburgTime.getTimezoneOffset();
        const timezoneAbbreviation = isDaylightSaving ? 'CEST' : 'CET';

        this.clockElement.textContent = `${hours}${colon}${minutes} ${timezoneAbbreviation}`;
    }

    startClock() {
        this.updateClock();
        setInterval(() => this.updateClock(), 1000);
    }
}