export default class ContactForm {
    init(root) {
        this.root = root;
        this.setupEventListeners();
    }

    setupEventListeners() {
        // Save scroll position before form submission
        const formButton = this.root.querySelector('button');
        formButton.addEventListener('click', () => {
            console.log('XXXXX')
            localStorage.setItem('scrollPosition', window.scrollY);
        });

        console.log('hihihi')

        // Restore scroll position on page load
        const scrollPosition = localStorage.getItem('scrollPosition');
        if (scrollPosition !== null) {
            window.scrollTo(0, parseInt(scrollPosition, 10));
            localStorage.removeItem('scrollPosition'); // Remove after restoring
        }
    }
}
