import Plyr from 'plyr';


export default class Video {
  init(root) {
      this.root = root
      const player = new Plyr(this.root, {
        controls: ['play-large', 'play', 'progress', 'current-time', 'mute', 'volume', 'captions', 'fullscreen']
      });

      // console.log(player)

      player.on('enterfullscreen', (event) => {
        // console.log('Entered fullscreen mode');
        const header = document.querySelector('.header')
        header.style.opacity = "0";
        header.style.pointerEvents = "none";
      });

      player.on('exitfullscreen', () => {
        // console.log('Exited fullscreen mode');
        const header = document.querySelector('.header')
        header.style.opacity = "1";
        header.style.pointerEvents = "auto";
      });
    }
}
